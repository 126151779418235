import React from "react";
import { connect } from "react-redux";
import { getDic } from "../assets/i18n/dictionary";
import { insertNewAccess, getSystemActions } from "../services/accessService";
import * as raffleService from "../services/raffleService";
import * as configService from "../services/configService";

//Imports de components
import {
  MenuButton,
  BackButton,
  Container,
  FormRow,
  TextMedium,
  Button,
  FormColumn,
  Alert,
  Loading,
  SelectList,
  WheelOfPrizes,
  Card,
  Avatar,
  TextBig,
  ActivityIndicator,
} from "../components";

const RafflePresentationPage = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: ''
  });
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [pageControl, setPageControl] = React.useState({
    backgroundImage: '',
    backgroundColor: '#e8eced',
    pages: ['selectRaffle', 'draw', 'winners'],
    chosenPage: 'selectRaffle'
  });
  const [rafflesControl, setRafflesControl] = React.useState({
    list: [],
    chosen: ''
  });
  const [drawControl, setDrawControl] = React.useState({
    segments: ["Inteegra", "Participe", "Prêmio", "Inteegra", "Participe", "Prêmio", "Inteegra"],
    colors: ["#F0CF50", "#815CD1", "#3DA5E0", "#34A24F", "#F9AA1F", "#FF9000", "#FF9000"],
    primaryColor: "black",
    contrastColor: "white",
    textButton: getDic("sorteio"),
    finishDraw: false,
    winners: []
  });
  const [cardStyleControl, setCardStyleControl] = React.useState({
    colorBackground: 'white',
    textColor: 'black',
    noAvatar: 'false',
    buttonColorBackground: '#3f51b5',
    buttonTextColor: 'white',
  });
  const [textStyleControl, setTextStyleControl] = React.useState({
    winnersText: '',
    textColor: 'black',
  });
  const [cardTransparent, setCardTransparent] = React.useState('normal');
  const isRaffling = React.useRef(false);

  React.useEffect(() => {
    //Bloqueio caso o usuário não seja admin
    if (!props.user.isAdmin) {
      props.history.goBack();
    }

    getPageData();

    var _helpButton = document.getElementById("launcher");
    if (_helpButton) {
      _helpButton.style.visibility = "hidden";
    }

    insertNewAccess(props.match.url, "", getSystemActions().accessPage);
    return () => {
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);

      _helpButton = document.getElementById("launcher");
      if (_helpButton) {
        _helpButton.style.visibility = "visible";
      }
    };
  }, []);

  const getPageData = async () => {
    try {
      let _rafflesRet = await raffleService.getRafflesListFirebase();
      let _raffles = [];
      _rafflesRet.forEach(item => {
        _raffles.push({
          ...item,
          text: item.title,
          value: item.id
        });
      });
      setRafflesControl({ ...rafflesControl, list: _raffles });

      await getPageConfig();

      setLoadingControl({ ...loadingControl, open: false });

      setIsLoading(false);
    } catch (error) {
      console.log("Error getPageData", error);
      setLoadingControl({ ...loadingControl, open: false });
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    }

    async function getPageConfig() {
      try {
        //Aplica configurações
        const _url = !props.isModal ? props.match.url : `/${props.event.id}/rafflepresentation`;
        const _configList = await configService.getConfigListByPage(_url);

        _configList.find((item) => {
          if (item.codeScreen === "IMAGE-001") {
            setPageControl({ ...pageControl, backgroundImage: item.url[0], backgroundColor: "#ffffff00" });
          }
          if (item.codeScreen === "BACKGROUND-COLOR") {
            setPageControl({ ...pageControl, backgroundColor: item.url[0] });
          }
          if (item.codeScreen === "ELEMENT-001") {
            let _cardStyleControl = { ...cardStyleControl };
            item.url.forEach(url => {
              let _url = url.split(':');
              _cardStyleControl[_url[0]] = _url[1];
            });
            setCardStyleControl(_cardStyleControl);
          }
          if (item.codeScreen === "ELEMENT-002") {
            let _drawControl = { ...drawControl };
            item.url.forEach(url => {
              let _url = url.split(':');
              if (url.includes('segments') || url.includes('colors')) {
                let _array = _url[1].split(',');
                let _finalArray = [];
                _array.forEach(a => {
                  let _a = a.replace(/_/g, " ");
                  _finalArray.push(_a);
                });
                _drawControl[_url[0]] = _finalArray;
              } else {
                _drawControl[_url[0]] = _url[1];
              }
            });
            setDrawControl(_drawControl);
          }
          if (item.codeScreen === "TITTLE-001") {
            let _textStyleControl = { ...textStyleControl };
            if (item.tooltip && item.tooltip !== '') {
              _textStyleControl.winnersText = item.tooltip;
            }
            item.url.forEach(url => {
              let _url = url.split(':');
              _textStyleControl[_url[0]] = _url[1];
            });
            setTextStyleControl(_textStyleControl);
          }
          if (item.codeScreen === "CARD-TRAN") {
            setCardTransparent('transparent');
          }
          return null;
        });
      } catch (error) {
        console.log('Error getPageConfig', error);
        throw error;
      }
    }
  }

  const realizeRaffle = async () => {
    let __raffle = rafflesControl.list.find(r => { return r.id === rafflesControl.chosen });

    try {
      if (isRaffling.current) { return; };

      isRaffling.current = true;

      let _minimunTime = false;
      setTimeout(() => {
        _minimunTime = true;
      }, 10000);

      let _winners = [];

      if (!__raffle.raffleRealized) {
        _winners = await realizeRaffleAndGetWinners();
      } else {
        _winners = await getRaffleWinners();
      }

      if (!_minimunTime) {
        setTimeout(() => {
          setWinnersAndShowPage(_winners);
        }, 5000);
      } else {
        setWinnersAndShowPage(_winners);
      }

    } catch (error) {
      console.log("Error realizeRaffle", error);
      setAlertControl({
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        open: true
      });
    } finally {
      isRaffling.current = false;
    }

    async function realizeRaffleAndGetWinners() {
      try {
        __raffle = await raffleService.realizeRaffle(__raffle);
        let _winners = await raffleService.getRaffleWinnersFirebase(__raffle.id);
        return _winners;
      } catch (error) {
        console.log("Error getDataAndRealizeRaffle", error);
        throw error;
      }
    }

    async function getRaffleWinners() {
      try {
        let _winners = await raffleService.getRaffleWinnersFirebase(__raffle.id);
        return _winners;
      } catch (error) {
        console.log("Error getRaffleWinners", error);
        throw error;
      }
    }

    function setWinnersAndShowPage(winners = []) {
      setDrawControl({ ...drawControl, winners: winners });
      setPageControl({ ...pageControl, chosenPage: 'winners' });
    }
  }

  if (isLoading) {
    return (
      <Container background="#e8eced" align="center">
        <FormRow align="center">
          <ActivityIndicator />
        </FormRow>
      </Container>
    )
  }
  return (
    <Container background={pageControl.backgroundColor} align="center">
      <FormColumn
        align="center"
        margin="0px"
        backgroundImage={pageControl.backgroundImage}
        backgroundSize="100% 100%"
      >
        <div style={{
          position: "relative",
          width: "100%", height:
            props.isModal ? props.height : "100vh",
          overflow: "auto"
        }}>
          {renderNavigationButtons()}
          {pageControl.chosenPage === 'selectRaffle' && (renderSelectRaffle())}
          {pageControl.chosenPage === 'draw' && (renderRaffleWheel())}
          {pageControl.chosenPage === 'winners' && (renderWinnersList())}
        </div>
      </FormColumn>

      <Alert
        open={alertControl.open}
        onClose={() => setAlertControl({ ...alertControl, open: false })}
        onClick={() => setAlertControl({ ...alertControl, open: false })}
        title={alertControl.title}
        message={alertControl.message}
      />

      <Loading open={loadingControl.open} message={loadingControl.message} />
    </Container>
  );

  function renderNavigationButtons() {
    return (
      <div style={{ width: '100%' }}>
        <MenuButton history={props.history} />
        <BackButton history={props.history} color="#212121">
          {getDic("voltar").toUpperCase()}
        </BackButton>
      </div>
    )
  }

  function renderSelectRaffle() {
    const onClick = () => {
      if (!rafflesControl.chosen) {
        setAlertControl({
          open: true,
          message: `${getDic("selecione")} ${getDic("um")} ${getDic("sorteio")}`
        });
        return;
      }
      setWinnersText();
      setPageControl({ ...pageControl, chosenPage: 'draw' });

      function setWinnersText() {
        if (!textStyleControl.winnersText) {
          let _raffle = rafflesControl.list.find(r => { return r.id === rafflesControl.chosen });
          let _text = getDic("vencedores").toUpperCase();
          if (parseInt(_raffle.numberOfWinners) <= 1) {
            _text = getDic("vencedor").toUpperCase();
          }
          setTextStyleControl({ ...textStyleControl, winnersText: _text });
        }
      }
    }

    return (
      <FormColumn margin="0px" width="100%" height="100%" align="center" alignItems="center">
        <Card
          backgroundColor={cardStyleControl.colorBackground}
          cardType={cardTransparent}
        >
          <div style={{ padding: 10, width: 500 }}>
            <FormRow margin="0px" paddingTop="5px">
              <SelectList
                title={getDic("sorteio")}
                width="100%"
                value={rafflesControl.chosen}
                menuItens={rafflesControl.list}
                onChange={e => setRafflesControl({ ...rafflesControl, chosen: e.target.value })}
              />
            </FormRow>
            <FormRow margin="0px" align="center" paddingTop="10px">
              <Button
                color={cardStyleControl.buttonColorBackground}
                textColor={cardStyleControl.buttonTextColor}
                text={getDic("avancar")}
                onClick={() => onClick()}
              />
            </FormRow>
          </div>
        </Card>
      </FormColumn>
    )
  }

  function renderRaffleWheel() {
    return (
      <>
        <FormRow margin="0px" align="center">
          <FormColumn margin="0px" width="1000" align="center" alignItems="center">
            <WheelOfPrizes
              segments={drawControl.segments}
              seg_colors={drawControl.colors}
              finishDraw={drawControl.finishDraw}
              onInitDraw={() => realizeRaffle()}
              primaryColor={drawControl.primaryColor}
              contrastColor={drawControl.contrastColor}
              buttonText={drawControl.textButton}
            />
          </FormColumn>
        </FormRow>
      </>
    )
  }

  function renderWinnersList() {
    let _raffle = rafflesControl.list.find(r => { return r.id === rafflesControl.chosen });

    const getFontSize = () => {
      let _textSize = '17px';
      if (drawControl.winners.length === 1) {
        _textSize = '35px';
      }
      if (drawControl.winners.length === 2) {
        _textSize = '30px';
      }
      if (drawControl.winners.length > 2 && drawControl.winners.length < 5) {
        _textSize = '25px';
      }
      return _textSize;
    }

    return (
      <FormColumn margin="0px" width="100%" height="100%" align="center" alignItems="center">
        <FormRow margin="0px" align="center">
          <TextBig color={textStyleControl.textColor}>
            {textStyleControl.winnersText}
          </TextBig>
        </FormRow>
        {drawControl.winners.length > 0 && (
          <FormRow margin="0px" align="center">
            {drawControl.winners.map((item, i) => {
              if (i >= _raffle.numberOfWinners) { return null };
              return (
                <div
                  key={item.guestId}
                  style={{
                    minWidth: "280px",
                    padding: 5,
                  }}
                >
                  <Card
                    padding="0px"
                    backgroundColor={cardStyleControl.colorBackground}
                    cardType={cardTransparent}
                  >
                    <div style={{ padding: 5 }}>
                      {cardStyleControl.noAvatar !== 'true' && (
                        <FormRow align="center" margin="0px" paddingTop="5px">
                          <Avatar src={item.guestPhoto} size="big" />
                        </FormRow>
                      )}
                      <FormRow align="center" margin="0px" paddingTop="5px">
                        <TextMedium fontSize={getFontSize()} color={cardStyleControl.textColor}>
                          {item.guestName}
                        </TextMedium>
                      </FormRow>
                      <FormRow align="center" margin="0px" paddingTop="5px" paddingBottom="5px">
                        <TextMedium fontSize={getFontSize()} color={cardStyleControl.textColor}>
                          {item.guestEmail}
                        </TextMedium>
                      </FormRow>
                    </div>
                  </Card>
                </div>
              );
            })}
          </FormRow>
        )}
      </FormColumn>
    )
  }
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(RafflePresentationPage);
