import axios from "axios";
import { store } from "../store";
import { setAccess } from "../store/actions";
import * as apiService from "./apiService";
import * as accessModel from "../models/accessModel";
import moment from "moment";
import ReactGA from "react-ga";

export async function insertNewAccessListApi(access) {
  var method =
    apiService.getUrl().traceability + "event/traceability/heatmap/register";
  const token = await apiService.getToken();
  var _currentDateStr = await apiService.getDate();
  const state = store.getState();

  const accessList = [];

  access
    .filter((e) => e.objectTypeDescription != "ping")
    .forEach((item) => {
      //Impedir que haja duplicidade ou registros com mais de 5 horas
      var _hasRegister = accessList.find((obj) => {
        return obj.accessDate === item.accessDate;
      });

      if (!_hasRegister) {
        var _currentDate = moment(_currentDateStr);
        var _itemDate = moment(item.accessDate);
        var _diffHours = _currentDate.diff(_itemDate, "hours");

        if (_diffHours < 4) {
          var acc = {
            applicationId: item.applicationId,
            actionId: item.actionId,
            page: item.page,
            accessDate: item.accessDate,
            ipAddress: item.ipAddress,
            loginId: item.loginId,
            login: item.login,
            note: item.note,
            objectDescription: item.objectDescription,
            objectTypeDescription: item.objectTypeDescription,
            eventId: item.eventId,
          };
          accessList.push(acc);
        }
      }
    });

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: accessList,
    };
    firebaseHealthMap(access); // remover
    axios(options)
      .then((res) => {
        if (
          state.config.filter((e) => e.codeScreen == "ENABLE-CHAT").length > 0
        ) {
          //pingUserOnline();
        }
        if (res.data.success) {
          resolve({ ok: true });
        } else {
          reject(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Erro insertNewAccessListApi", err);
        reject(err);
      });
  });
}

//function temporária
async function firebaseHealthMap(access) {
  var method = apiService.getUrl().firebase + "HealthMap";
  const token = await apiService.getToken();
  var _currentDateStr = await apiService.getDate();
  const _chosenConfigOptions = getChosenConfigOptions();
  const accessList = [];

  access
    .filter((e) => e.objectTypeDescription == "ping")
    .forEach((item) => {
      //Impedir que haja duplicidade ou registros com mais de 5 horas
      var _hasRegister = accessList.find((obj) => {
        return obj.accessDate === item.accessDate;
      });

      if (!_hasRegister) {
        var _currentDate = moment(_currentDateStr);
        var _itemDate = moment(item.accessDate);
        var _diffHours = _currentDate.diff(_itemDate, "hours");

        const _path = getPath();

        if (_diffHours < 4) {
          var acc = {
            applicationId: item.applicationId,
            actionId: item.actionId,
            page: _path ? _path : item.page,
            accessDate: item.accessDate,
            ipAddress: "",
            loginId: item.loginId,
            login: item.login,
            note: item.note,
            objectDescription: item.objectDescription,
            objectTypeDescription: item.objectTypeDescription,
            eventId: item.eventId,
            scheduleId: _chosenConfigOptions.scheduleId,
            configId: _chosenConfigOptions.configId,
            description: _chosenConfigOptions.description,
          };
          accessList.push(acc);
        }
      }
    });

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: accessList,
    };
    axios(options)
      .then((res) => { })
      .catch((err) => {
        console.log("Erro firebaseHealthMap", err);
        reject(err);
      });
  });
}

function getChosenConfigOptions() {
  const _chosenConfig = store.getState().chosenConfig;

  var _returnObj = {
    scheduleId: null,
    configId: null,
    description: null,
  };

  if (_chosenConfig) {
    _returnObj.description = _chosenConfig.tooltip;

    if (_chosenConfig.scheduleId && _chosenConfig.scheduleId > 0) {
      _returnObj.scheduleId = _chosenConfig.scheduleId;
    } else {
      _returnObj.configId = _chosenConfig.id;
      if (_chosenConfig.description && _chosenConfig.description !== "") {
        _returnObj.description = _chosenConfig.description;
      }
    }
  }
  return _returnObj;
}

function getPath() {
  const _urlArray = window.location.pathname.split("&");
  const _pathArray = _urlArray[0].split("/");
  return _pathArray[2] ? _pathArray[2] : window.location.pathname;
}

export function insertNewAccess(
  page = "",
  note = "",
  action,
  objectTable = null,
  objectTableId = null
) {
  const _user = store.getState().user;
  const _event = store.getState().event;

  //Insere acesso no Google Analytics
  if (_event && _event.hasGoogleAnalytics && action && action.id === 4) {
    ReactGA.pageview(page, {
      gaOptions: {
        userId: _user.id,
      },
    });
  }

  return new Promise(async (resolve, reject) => {
    const _objectDescription = page.split("/");
    const _date = await apiService.getDate();

    if (_user && _event) {
      const _access = accessModel.createAccessModel(
        action.id,
        _objectDescription[2],
        _date,
        "",
        _user.id.toString(),
        _user.email,
        note,
        objectTableId != null ? objectTableId : _objectDescription[2],
        objectTable != null ? objectTable : _objectDescription[2],
        _event.id
      );

      store.dispatch(setAccess(_access));
      resolve(true);
    } else {
      resolve(true);
    }
  });
}

export async function pingUserOnline() {
  var method = apiService.getUrl().chat + "/UserOnline";
  const token = await apiService.getToken();
  const state = store.getState();
  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: {
        guestId: state.user.id,
        eventId: state.event.id,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          resolve({ ok: true });
        } else {
          reject(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Erro insertNewAccessListApi", err);
        reject(err);
      });
  });
}

export function getSystemActions() {
  var actions = {
    accessPage: {
      id: 4,
      name: "Acessar Página",
    },
    exitPage: {
      id: 5,
      name: "Sair da Página",
    },
    click: {
      id: 6,
      name: "Click",
    },
    accessStand: {
      id: 7,
      name: "Entrou Stand",
    },
    ping: {
      id: 10,
      name: "Ping",
    },
  };

  return actions;
}

export function getSystemObjects() {
  var actions = {
    schedulePage: {
      id: 3,
      name: "TB_ProgramacaoEvento",
    },
    standPage: {
      id: 21,
      name: "stand",
    },
    standsPage: {
      id: 22,
      name: "stands",
    },
    eventHallPage: {
      id: 23,
      name: "eventhall",
    },

    notFoundPage: {
      id: 24,
      name: "",
    },
    schedulesPage: {
      id: 25,
      name: "schedules",
    },
    badgePage: {
      id: 26,
      name: "badge",
    },
    createAvatar: {
      id: 27,
      name: "createavatar",
    },
    Transmission: {
      id: 28,
      name: "transmission",
    },
  };

  return actions;
}

export async function checkUserAlreadyLogged(event_id, guest_id, user_token) {
  var method = apiService.getUrl().firebase + "CheckAccess";
  const token = await apiService.getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: `${method}?eventId=${event_id}&userId=${guest_id}&token=${user_token}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro checkUserAlreadyLogged", err);
        reject(err);
      });
  });
}

export async function addGatewayAccess(configId = '') {
  try {
    let _userId = store.getState().user.id;

    const options = {
      url: `${apiService.getUrl().gateway}historyview/videos`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        guestId: parseInt(_userId),
        configId: parseInt(configId)
      }
    };

    await axios(options);

    return;
  } catch (error) {
    console.log('Error addGatewayAccess', error);
    throw error;
  }
}
