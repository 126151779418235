import * as msal from "@azure/msal-browser";
import { store } from "../store";
import * as actions from "../store/actions";
import * as apiService from "./apiService";
import * as attributesService from "./attributesService";
import * as userModel from "../models/userModel";
import * as tokenModel from "../models/tokenModel";
import axios from "axios";
import moment from "moment";

const msalConfig = {
  auth: {
    clientId: "31dd437f-4d0f-4f3e-834e-b6363e451939",
    //clientId: '5c2250f2-592f-4561-a8b6-8a2c4d32e9c8', 24/07/2024
    // clientId: "784ff857-f64a-48ff-9a91-7610bdf40e78",
    authority:
      "https://login.microsoftonline.com/84dc3018-9598-4df2-a3bd-995094f6640b",
    // redirectUri: 'https://sso-lideragb.grupoboticario.com.br'
  },
};
const msalInstance = new msal.PublicClientApplication(msalConfig);

export default {
  async initialize() {
    try {
      await msalInstance.initialize();
    } catch (error) {
      console.log("Error initialize", error);
      throw error;
    }
  },

  async openLoginPopup() {
    try {
      let _res = await msalInstance.loginPopup({
        scopes: ["openid", "profile", "email"],
      });

      let _user;
      if (_res.account.username && _res.account.name) {
        _user = await checkIfUserExists(_res.account.username);
      }

      if (!_user) {
        _user = await registerUserApi(_res.account.name, _res.account.username);
      }

      let _return = userModel.createUserModel(
        _user.id,
        store.getState().event.id,
        _user.name,
        _user.email,
        _user.institution ? _user.institution : "",
        _user.avatarProfileLink ? _user.avatarProfileLink : "",
        _user.hallFirstTerm,
        _user.hallSecondTerm,
        null,
        null,
        null,
        _user.groupId,
        _user.registration,
        _user.registration,
        _user.password,
        _user.company,
        _user.visibleToChat,
        _user.ranking && _user.ranking.levelId ? _user.ranking.levelId : null,
        _user.ranking && _user.ranking.level && _user.ranking.level.name
          ? _user.ranking.level.name
          : null,
        _user.ranking && _user.ranking.points ? _user.ranking.points : null,
        _user.specialty,
        _user.ranking && _user.ranking.position ? _user.ranking.position : null
      );

      let _userToken = tokenModel.createTokenModel(
        _user.accessToken || "",
        moment().format()
      );
      store.dispatch(actions.setToken(_userToken));

      return _return;
    } catch (error) {
      console.log("Error openLoginPopup", error);
      throw error;
    }

    async function checkIfUserExists(email = "") {
      try {
        let _user;
        try {
          let _token = await apiService.getToken(email);
          const options = {
            url: apiService.getUrl().direct + "hall/api/event/guest/login",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + _token,
            },
            data: {
              type: 2,
              text: email,
              eventId: store.getState().event.id,
              password: null,
            },
          };

          let _res = await axios(options);

          _user = _res.data.data;
          _user.accessToken = _res.data.message;

          return _user;
        } catch (error) {
          console.log("Error getUser", error);
        }

        if (_user && _user.id && _user.id > 0) {
          return _user;
        } else {
          return null;
        }
      } catch (error) {
        console.log("Error checkIfUserExists", error);
        return null;
      }
    }

    async function registerUserApi(name = "", email = "") {
      let _attributesList = await attributesService.getAttributes(
        store.getState().event.id
      );
      let _attributeName = _attributesList.find((a) => {
        return a.dbReference === "Nome";
      });
      let _attributeNEmail = _attributesList.find((a) => {
        return a.dbReference === "Email";
      });

      let token = await apiService.getToken();

      const attributesApi = [
        {
          attributeId: _attributeName.id,
          value: name,
        },
        {
          attributeId: _attributeNEmail.id,
          value: email,
        },
      ];

      return new Promise((resolve, reject) => {
        const options = {
          url: apiService.getUrl().direct + "hall/api/event/guest/register",
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token,
          },
          data: [
            {
              contractId: parseInt(store.getState().event.contractId),
              eventId: parseInt(store.getState().event.id),
              attributes: attributesApi,
            },
          ],
        };
        axios(options)
          .then((res) => {
            if (
              res.data.success &&
              res.data.data.guest &&
              res.data.data.guest.id
            ) {
              let _user = res.data.data.guest;
              _user.accessToken = res.data.data.token;

              resolve(_user);
            } else {
              reject(res.data);
            }
            return;
          })
          .catch((error) => {
            console.log("Erro registerUserApi", error);
            reject(error);
            return error;
          });
      });
    }
  },
};
