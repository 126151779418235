import axios from "axios";
import { store } from "../store";
import { getToken, getUrl } from "./apiService";
import { createGuestModel } from "../models/guestModel";

export async function getGuestListV2(index = 1, search = "", guestId) {
  const token = await getToken();
  const event = store.getState().event;
  var method =
    getUrl().base +
    "guest-search/api/event/guest/list/?eventId=" +
    event.id.toString() +
    "&pageIndex=" +
    index.toString() +
    `&pageSize=30&guestId=${guestId ? guestId : 0}`;

  if (search && search !== "") {
    method = method + "&search=" + search;
  }

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          const guestList = [];
          res.data.data.guests.forEach((item) => {
            const guest = createGuestModel(
              item.id,
              item.name,
              item.company,
              item.jobTitle,
              item.avatarProfileLink,
              item.email,
              item.groupId
            );
            guestList.push(guest);
          });

          resolve({ guestList, totalPages: res.data.data.totalPages });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getGuestList", err);
        reject(err);
      });
  });
}

export async function getGuestList(index = 1, search = "", groupsIds = []) {
  const token = await getToken();
  const event = store.getState().event;
  var method = getUrl().hall + "event/guest/list";

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: {
        EventId: event.id,
        PageIndex: index,
        PageSize: 30,
        Search: search,
        GroupIds: groupsIds,
      }

    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          const guestList = [];
          res.data.data.guests.forEach((item) => {
            const guest = createGuestModel(
              item.id,
              item.name,
              item.company,
              item.jobTitle,
              item.avatarProfileLink,
              item.email,
              item.groupId
            );
            guestList.push(guest);
          });

          resolve({ guestList, totalPages: res.data.data.totalPages });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getGuestList", err);
        reject(err);
      });
  });
}

export async function getCountOnlineGuests() {
  const token = await getToken();
  const event = store.getState().event;
  var method =
    getUrl().hall +
    "event/traceability/guest/online/counter/" +
    event.id.toString();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success && res.data.data) {
          //O contador de pessoas online foi transferido para um novo endpoint
          method = getUrl().firebase + "GetOnlineUsers?eventId=" +
            event.id.toString();
          const options = {
            url: method,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + token,
            },
          };
          axios(options)
            .then((res2) => {
              if (res2 && res2.data.success) {
                //res.data.data =>
                // totalPax: res.total,
                // totalPaxCheckin: res.checked,
                // totalPaxOnline: res.online,

                const _guestsOnlineIds = [];
                res2.data.data.forEach((item) => {
                  _guestsOnlineIds.push(parseInt(item.loginId));
                });

                var _returnObj = {
                  total: res.data.data.total,
                  checked: res.data.data.checked,
                  online: res2.data.length,
                  onlineGuestsId: _guestsOnlineIds,
                };

                resolve(_returnObj);
              } else {
                console.log("Erro getCountOnlineGuests", res2);
                reject(res2);
              }
            })
            .catch((err) => {
              console.log("Erro getCountOnlineGuests", err);
              reject(err);
            });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getCountOnlineGuests", err);
        reject(err);
      });
  });
}

export async function getGuestCheckinsCountByDate() {
  const token = await getToken();
  const event = store.getState().event;
  var method =
    getUrl().hall +
    "event/traceability/guest/online/checkinperiod/" +
    event.id.toString();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getCountOnlineGuests", err);
        reject(err);
      });
  });
}

export async function getOnlineGuests(index = 1, search = "") {
  const token = await getToken();
  const event = store.getState().event;
  var method =
    getUrl().hall +
    "event/traceability/guest/online/list?eventId=" +
    event.id.toString() +
    "&pageIndex= " +
    index.toString() +
    "&pageSize=30&minutes=2&search=" +
    search;
  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          const returnList = [];

          if (res.data.data.guests && res.data.data.guests.length > 0) {
            res.data.data.guests.forEach((item) => {
              //Tratamento para evitar ids repetidos
              var hasPax = returnList.find((pax) => {
                if (pax.id === item.id) {
                  return true;
                }
                return false;
              });

              if (!hasPax) {
                const obj = {
                  id: item.id,
                  name: item.name,
                  email: item.email,
                };
                returnList.push(obj);
              }
            });
          }

          resolve({
            guestList: returnList,
            totalPages: res.data.data.totalPages,
          });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getCountOnlineGuests", err);
        reject(err);
      });
  });
}
